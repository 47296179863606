import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.css']
})
export class UserWalletComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
