<amplify-auth-container>
        <amplify-authenticator *ngIf="authState !== 'signedin'"></amplify-authenticator>
</amplify-auth-container>
<!-- Toolbar -->
<div id="main-block" *ngIf="authState === 'signedin' && user">
<header id="header">
        <a routerLink="/user-home">Home</a>&nbsp;
        <a routerLink="/user-feed" routerLinkActive="active">Feed</a>&nbsp;
        <a routerLink="/user-profile" routerLinkActive="active">Profile</a>&nbsp;
        <a routerLink="/user-wallet" routerLinkActive="active">Wallet</a>&nbsp;
        <a routerLink="/user-settings" routerLinkActive="active">Settings</a>&nbsp;
        <amplify-sign-out></amplify-sign-out>
</header>

<div id="router-block"><router-outlet></router-outlet></div>

<!-- Footer -->
<footer>
</footer>
</div>