/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:fa8b8a4d-22c7-4814-af19-459623b802d0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_NZ6ROTjDb",
    "aws_user_pools_web_client_id": "5q8sp133taet0i6k3e7vmtu497",
    "oauth": {},
    "aws_cognito_login_mechanism": [
        "EMAIL",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "12",
        "passwordPolicyCharacters": []
    }
};


export default awsmobile;
