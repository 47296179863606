import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserFeedComponent } from './user-feed/user-feed.component'
import { UserHomeComponent } from './user-home/user-home.component'
import { UserProfileComponent } from './user-profile/user-profile.component'
import { UserSettingsComponent } from './user-settings/user-settings.component'
import { UserWalletComponent } from './user-wallet/user-wallet.component';

const routes: Routes = [
  { path: 'user-feed', component: UserFeedComponent },
  { path: 'user-home', component: UserHomeComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'user-settings', component: UserSettingsComponent },
  { path: 'user-wallet', component: UserWalletComponent },
  { path: '', redirectTo: '/user-home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
